.App {
  text-align: center;
  background-color: rgb(240, 212, 203);
}
.App::before {
  content:'';
  background-color: rgb(238, 102, 57);
  position: absolute;
  left: -90vw;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  transform: skewX(-10deg);
}

.App * {
  z-index: 1;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.vipps-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0.5rem;
}

.izettle-logo {
  height: 10vmin;
  pointer-events: none;
  margin: 0.5rem;
}

.logos {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: calc(20px + 2vmin);
  line-height: 0.5em;
}

.logos > div {
  cursor: pointer;
}

.gsmk-logo {
  height: 80vh;
  pointer-events: none;
  position: absolute;
  right: -5%;
  opacity: 0.3;
}

.vipps-number {
  font-weight: 900;
  margin-bottom: 0.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: black;
}
.App-header .dark {
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #264f5a;
}

.App-link .dark {
  color: #61dafb;
}
.App-days {
  display: flex;
  gap: 2rem;
  margin: 1rem;
}
.day {
  font-size: 4vmin;
}
.day-amount {
  font-weight: bold;
  font-size: 6vmin;
}
.details-amount {
  font-weight: bold;
  font-size: 2vmin;
}

.card {
  margin-left: 3vmin;
  margin-right: 3vmin;
  margin-top: 1vh;
  margin-bottom: 1vh;
  background-color: white;
  align-items: center;
  box-shadow: 
    12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  justify-content: center;
  padding: 1rem;
  min-width: 15vw;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
